<script>
export default {
    mounted() {
        if (!this.$refs.loadMoreIntersect) {
            return;
        }
        const observer = new IntersectionObserver(entries => entries.forEach(entry => entry.isIntersecting && this.loadMoreArticles(), {
            rootMargin : "-250px 0px 0px 0px"
        }));

        observer.observe(this.$refs.loadMoreIntersect)
    },
    data() {
        return {
            allArticles : this.articles?.data ?? [],
            page        : 1,
        }
    },
    methods  : {
        loadMoreArticles() {
            if (this.articles.next_page_url === null) {
                return
            }

            this.$inertia.post(this.$page.url, {
                page : ++this.page
            }, {
                preserveState  : true,
                preserveScroll : true,
                only           : ['articles'],
                onSuccess      : () => {
                    this.allArticles = [...this.allArticles, ...this.articles?.data]
                }
            })
        }
    },
    computed : {
        hasArticles() {
            return this.articles && this.articles.data?.length > 0;
        },
    },
}
</script>

<style scoped>

</style>